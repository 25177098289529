body {
   margin: 0;
   font-family: "Muli", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol", "Noto Color Emoji";
   font-size: 1rem;
   font-weight: 400;
   line-height: 1.5;
   color: #212529;
   text-align: left;
   background-color: #fff;
   padding-top: 3.375rem;
   color: #6c757d;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
}

.animated-div {
   font-size: 2.25rem;
}

.software {
   font-weight: bolder;
   letter-spacing: 1px;
   -webkit-animation: light 3.6s infinite;
   animation: light 3.6s infinite;
}

.rainbow-img {
   height: 40px;
}

@-webkit-keyframes light {

   0%,
   20% {
      color: #f0a00b;
      text-shadow: 0 0 10px #f0a00b;
   }

   40% {
      color: #40f00b;
      text-shadow: 0 0 10px #40f00b;
   }

   60% {
      color: #0b94f0;
      text-shadow: 0 0 10px #0b94f0;
   }

   80% {
      color: #f00b89;
      text-shadow: 0 0 10px #f00b89;
   }

   100% {
      color: #38ffa2;
      text-shadow: 0 0 10px #38ffa2;
   }
}

@keyframes light {

   0%,
   20% {
      color: #f0a00b;
      text-shadow: 0 0 10px #f0a00b;
   }

   40% {
      color: #40f00b;
      text-shadow: 0 0 10px #40f00b;
   }

   60% {
      color: #0b94f0;
      text-shadow: 0 0 10px #0b94f0;
   }

   80% {
      color: #f00b89;
      text-shadow: 0 0 10px #f00b89;
   }

   100% {
      color: #38ffa2;
      text-shadow: 0 0 10px #38ffa2;
   }
}

.software:nth-child(1) {
   -webkit-animation-delay: 0.2s;
   animation-delay: 0.2s;
}

.software:nth-child(2) {
   -webkit-animation-delay: 0.4s;
   animation-delay: 0.4s;
}

.software:nth-child(3) {
   -webkit-animation-delay: 0.6s;
   animation-delay: 0.6s;
}

.software:nth-child(4) {
   -webkit-animation-delay: 0.8s;
   animation-delay: 0.8s;
}

.software:nth-child(5) {
   -webkit-animation-delay: 1s;
   animation-delay: 1s;
}

.software:nth-child(6) {
   -webkit-animation-delay: 1.2s;
   animation-delay: 1.2s;
}

.software:nth-child(7) {
   -webkit-animation-delay: 1.4s;
   animation-delay: 1.4s;
}

.software:nth-child(8) {
   -webkit-animation-delay: 1.6s;
   animation-delay: 1.6s;
}

.software:nth-child(9) {
   -webkit-animation-delay: 1.8s;
   animation-delay: 1.8s;
}

.software:nth-child(10) {
   -webkit-animation-delay: 2s;
   animation-delay: 2s;
}

.software:nth-child(11) {
   -webkit-animation-delay: 2.2s;
   animation-delay: 2.2s;
}

.software:nth-child(12) {
   -webkit-animation-delay: 2.4s;
   animation-delay: 2.4s;
}

.software:nth-child(13) {
   -webkit-animation-delay: 2.6s;
   animation-delay: 2.6s;
}

.software:nth-child(14) {
   -webkit-animation-delay: 2.8s;
   animation-delay: 2.8s;
}

.software:nth-child(15) {
   -webkit-animation-delay: 3s;
   animation-delay: 3s;
}

.software:nth-child(16) {
   -webkit-animation-delay: 3.2s;
   animation-delay: 3.2s;
}

.software:nth-child(17) {
   -webkit-animation-delay: 3.4s;
   animation-delay: 3.4s;
}

.software:nth-child(18) {
   -webkit-animation-delay: 3.6s;
   animation-delay: 3.6s;
}

/*------------------------
=> Contact Page
--------------------------*/

.wrapper-contact {
   background-color: #389ebd;
   width: -webkit-fit-content;
   width: -moz-fit-content;
   width: fit-content;
   padding: 2em;
   width: 100%;
}

.wrapper-contact input {
   width: 100%;
   height: 2rem;
}

#form-message {
   width: 100%;
   height: 100%;
}

#btn-form input {
   border: none;
   width: 50%;
   height: auto;
   display: flex;
   justify-content: center;
   margin: 0 auto;
   padding: 1rem;
   border: none;
   text-transform: uppercase;
   letter-spacing: 0.1rem;
   transition: ease-in-out 0.5s;
}

#btn-form {
   margin-bottom: none;
}

#btn-form input:hover {
   cursor: pointer;
   background-color: #bde8ff !important;
}

.alert {
   text-align: center;
   padding: 10px;
   font-size: 1.2rem;
   font-weight: 600;
   background: #bde8ff;
   color: #000;
   border-radius: 0 3rem 0 3rem;
}

/*
=======================================
==>        Animations  
=========================================
*/

.animated {
   -webkit-animation-duration: 1s;
   animation-duration: 1s;
   -webkit-animation-fill-mode: both;
   animation-fill-mode: both;
}

.animated.delay-1s {
   -webkit-animation-delay: 1s;
   animation-delay: 1s;
   -webkit-animation-delay: 1s;
   animation-delay: 1s;
}


.fadeInRight {
   -webkit-animation-name: fadeInRight;
   animation-name: fadeInRight;
}


.fadeInUp {
   -webkit-animation-name: fadeInUp;
   animation-name: fadeInUp;
}

/************************
=       Fade Right
*************************/
@-webkit-keyframes fadeInRight {
   from {
      opacity: 0;
      transform: translate3d(50px, 0, 0);
   }

   to {
      opacity: 1;
      transform: none;
   }
}

@keyframes fadeInRight {
   from {
      opacity: 0;
      transform: translate3d(50px, 0, 0);
   }

   to {
      opacity: 1;
      transform: none;
   }
}

/************************
=       Fade UP
*************************/
@-webkit-keyframes fadeInUp {
   from {
      opacity: 0;
      visibility: hidden;
      transform: translate3d(0, 40px, 0);
   }

   to {
      opacity: 1;
      transform: none;
   }
}

@keyframes fadeInUp {
   from {
      opacity: 0;
      visibility: hidden;
      transform: translate3d(0, 40px, 0);
   }

   to {
      visibility: visible;
      opacity: 1;
      transform: none;
   }
}

/*
=======================================
==>        END-Animations  
=======================================
*/

/************************
=       Blog Cards
*************************/

.request-icon {
   font-size: 5em;
   margin-top: 0.5em;
}

.card-container {
   display: flex;
   justify-content: space-evenly;
   flex-wrap: wrap;
}

.flip-card {
   background-color: transparent;
   width: 300px;
   height: 300px;
   margin: 1em;
}

.flip-card-inner {
   position: relative;
   width: 100%;
   height: 100%;
   text-align: center;
   transition: transform 0.75s;
   transform-style: preserve-3d;
   box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.2);
}

.flip-card:hover .flip-card-inner {
   transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
   position: absolute;
   width: 100%;
   height: 100%;
   -webkit-backface-visibility: hidden;
   backface-visibility: hidden;
}

.flip-card-front {
   background-color: rgb(237, 246, 249);
   padding: 1em;
}

.flip-card-back {
   background-color: rgb(204, 238, 250);
   transform: rotateY(180deg);
   text-align: left;
   padding: 1em;
   color: black;
}

.card-description {
   margin-top: 1em;
   font-size: 1.25em;
}

.project-card {
   margin-top: 0em;
   font-size: 1em;
}

.projects-small {
   font-size: 1rem;
}

.project-list {
   list-style: none;
   padding-left: 0px;
   padding-top: 1em;
   padding-bottom: -1em;
}

.project-icon {
   margin-right: 0.15em;
   font-size: 1.5em;
}

.project-link {
   margin: 0.25em;
}

.card-link {
   color: blue;
   font-size: 1.15em;
   margin-right: 1em;
}

.card-link:hover {
   color: rgb(62, 147, 252);
}

.app-img {
   height: 200px;
   max-width: 50%;
}

.award-li {
   margin-bottom: 1em;
}

*,
*::before,
*::after {
   box-sizing: border-box;
}

hr {
   box-sizing: content-box;
   height: 0;
   overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
   margin-top: 0;
   margin-bottom: 0.5rem;
}

p {
   margin-top: 0;
   margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
   text-decoration: underline;
   -webkit-text-decoration: underline dotted;
   text-decoration: underline dotted;
   cursor: help;
   border-bottom: 0;
   -webkit-text-decoration-skip-ink: none;
   text-decoration-skip-ink: none;
}

address {
   margin-bottom: 1rem;
   font-style: normal;
   line-height: inherit;
}

ol,
ul,
dl {
   margin-top: 0;
   margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
   margin-bottom: 0;
}

dt {
   font-weight: 700;
}

dd {
   margin-bottom: 0.5rem;
   margin-left: 0;
}

blockquote {
   margin: 0 0 1rem;
}

b,
strong {
   font-weight: bolder;
}

small {
   font-size: 80%;
}

sub,
sup {
   position: relative;
   font-size: 75%;
   line-height: 0;
   vertical-align: baseline;
}

sub {
   bottom: -0.25em;
}

sup {
   top: -0.5em;
}

a {
   color: #389ebd;
   text-decoration: none;
   background-color: transparent;
}

a:hover {
   color: #3e9ea9;
   text-decoration: underline;
}

a:not([href]) {
   color: inherit;
   text-decoration: none;
}

a:not([href]):hover {
   color: inherit;
   text-decoration: none;
}

img {
   vertical-align: middle;
   border-style: none;
}

[role="button"] {
   cursor: pointer;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
   -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
   padding: 0;
   border-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
   margin-bottom: 0.5rem;
   font-family: "Saira Extra Condensed", -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
   font-weight: 700;
   line-height: 1.2;
   color: #343a40;
}

h1,
.h1 {
   font-size: 6rem;
}

h2,
.h2 {
   font-size: 3.5rem;
}

h3,
.h3 {
   font-size: 2rem;
}

h4,
.h4 {
   font-size: 1.5rem;
}

h5,
.h5 {
   font-size: 1.25rem;
}

h6,
.h6 {
   font-size: 1rem;
}

.list-inline {
   padding-left: 0;
   list-style: none;
}

.list-inline-item {
   display: inline-block;
}

.list-inline-item:not(:last-child) {
   margin-right: 0.5rem;
}

.tooltip:hover {
   color: rgb(65, 65, 65);
}

.tooltip {
   margin-top: 2rem;
}

.img-fluid {
   max-width: 100%;
   height: auto;
}

.img-thumbnail {
   padding: 0.25rem;
   background-color: #fff;
   border: 1px solid #dee2e6;
   border-radius: 0.25rem;
   max-width: 100%;
   height: auto;
}

.collapse:not(.show) {
   display: none;
}

.collapsing {
   position: relative;
   height: 0;
   overflow: hidden;
   transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
   .collapsing {
      transition: none;
   }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
   position: relative;
}

.nav {
   display: flex;
   flex-wrap: wrap;
   padding-left: 0;
   margin-bottom: 0;
   list-style: none;
}

.nav-link {
   display: block;
   padding: 0.5rem 1rem;
}

.nav-link:hover,
.nav-link:focus {
   text-decoration: none;
}

.nav-link.disabled {
   color: #6c757d;
   pointer-events: none;
   cursor: default;
}

.navbar {
   position: relative;
   display: flex;
   flex-wrap: wrap;
   align-items: center;
   justify-content: space-between;
   padding: 0.5rem 1rem;
}

.navbar-brand {
   display: inline-block;
   padding-top: 0.3125rem;
   padding-bottom: 0.3125rem;
   margin-right: 1rem;
   font-size: 1.25rem;
   line-height: inherit;
   white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
   text-decoration: none;
}

.navbar-nav {
   display: flex;
   flex-direction: column;
   padding-left: 0;
   margin-bottom: 0;
   list-style: none;
}

.navbar-nav .nav-link {
   padding-right: 0;
   padding-left: 0;
}

.navbar-nav .dropdown-menu {
   position: static;
   float: none;
}

.navbar-collapse {
   flex-basis: 100%;
   flex-grow: 1;
   align-items: center;
}

.navbar-toggler {
   padding: 0.25rem 0.75rem;
   font-size: 1.25rem;
   line-height: 1;
   background-color: transparent;
   border: 1px solid transparent;
   border-radius: 0.25rem;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
   text-decoration: none;
   cursor: pointer;
}

.navbar-toggler-icon {
   display: inline-block;
   width: 1.5em;
   height: 1.5em;
   vertical-align: middle;
   content: "";
   background: no-repeat center center;
   background-size: 100% 100%;
}

@media (max-width: 575.98px) {

   .navbar-expand-sm>.container,
   .navbar-expand-sm>.container-fluid,
   .navbar-expand-sm>.container-sm,
   .navbar-expand-sm>.container-md,
   .navbar-expand-sm>.container-lg,
   .navbar-expand-sm>.container-xl {
      padding-right: 0;
      padding-left: 0;
   }
}

@media (min-width: 576px) {
   .navbar-expand-sm {
      flex-flow: row nowrap;
      justify-content: flex-start;
   }

   .navbar-expand-sm .navbar-nav {
      flex-direction: row;
   }

   .navbar-expand-sm .navbar-nav .dropdown-menu {
      position: absolute;
   }

   .navbar-expand-sm .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
   }

   .navbar-expand-sm>.container,
   .navbar-expand-sm>.container-fluid,
   .navbar-expand-sm>.container-sm,
   .navbar-expand-sm>.container-md,
   .navbar-expand-sm>.container-lg,
   .navbar-expand-sm>.container-xl {
      flex-wrap: nowrap;
   }

   .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
   }

   .navbar-expand-sm .navbar-toggler {
      display: none;
   }
}

@media (max-width: 767.98px) {

   .navbar-expand-md>.container,
   .navbar-expand-md>.container-fluid,
   .navbar-expand-md>.container-sm,
   .navbar-expand-md>.container-md,
   .navbar-expand-md>.container-lg,
   .navbar-expand-md>.container-xl {
      padding-right: 0;
      padding-left: 0;
   }
}

@media (min-width: 768px) {
   .navbar-expand-md {
      flex-flow: row nowrap;
      justify-content: flex-start;
   }

   .navbar-expand-md .navbar-nav {
      flex-direction: row;
   }

   .navbar-expand-md .navbar-nav .dropdown-menu {
      position: absolute;
   }

   .navbar-expand-md .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
   }

   .navbar-expand-md>.container,
   .navbar-expand-md>.container-fluid,
   .navbar-expand-md>.container-sm,
   .navbar-expand-md>.container-md,
   .navbar-expand-md>.container-lg,
   .navbar-expand-md>.container-xl {
      flex-wrap: nowrap;
   }

   .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
   }

   .navbar-expand-md .navbar-toggler {
      display: none;
   }
}

@media (max-width: 991.98px) {

   .navbar-expand-lg>.container,
   .navbar-expand-lg>.container-fluid,
   .navbar-expand-lg>.container-sm,
   .navbar-expand-lg>.container-md,
   .navbar-expand-lg>.container-lg,
   .navbar-expand-lg>.container-xl {
      padding-right: 0;
      padding-left: 0;
   }
}

@media (min-width: 992px) {
   .navbar-expand-lg {
      flex-flow: row nowrap;
      justify-content: flex-start;
   }

   .navbar-expand-lg .navbar-nav {
      flex-direction: row;
   }

   .navbar-expand-lg .navbar-nav .dropdown-menu {
      position: absolute;
   }

   .navbar-expand-lg .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
   }

   .navbar-expand-lg>.container,
   .navbar-expand-lg>.container-fluid,
   .navbar-expand-lg>.container-sm,
   .navbar-expand-lg>.container-md,
   .navbar-expand-lg>.container-lg,
   .navbar-expand-lg>.container-xl {
      flex-wrap: nowrap;
   }

   .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
   }

   .navbar-expand-lg .navbar-toggler {
      display: none;
   }
}

@media (max-width: 1199.98px) {

   .navbar-expand-xl>.container,
   .navbar-expand-xl>.container-fluid,
   .navbar-expand-xl>.container-sm,
   .navbar-expand-xl>.container-md,
   .navbar-expand-xl>.container-lg,
   .navbar-expand-xl>.container-xl {
      padding-right: 0;
      padding-left: 0;
   }
}

@media (min-width: 1200px) {
   .navbar-expand-xl {
      flex-flow: row nowrap;
      justify-content: flex-start;
   }

   .navbar-expand-xl .navbar-nav {
      flex-direction: row;
   }

   .navbar-expand-xl .navbar-nav .dropdown-menu {
      position: absolute;
   }

   .navbar-expand-xl .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
   }

   .navbar-expand-xl>.container,
   .navbar-expand-xl>.container-fluid,
   .navbar-expand-xl>.container-sm,
   .navbar-expand-xl>.container-md,
   .navbar-expand-xl>.container-lg,
   .navbar-expand-xl>.container-xl {
      flex-wrap: nowrap;
   }

   .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
   }

   .navbar-expand-xl .navbar-toggler {
      display: none;
   }
}

.navbar-expand {
   flex-flow: row nowrap;
   justify-content: flex-start;
}

.navbar-expand>.container,
.navbar-expand>.container-fluid,
.navbar-expand>.container-sm,
.navbar-expand>.container-md,
.navbar-expand>.container-lg,
.navbar-expand>.container-xl {
   padding-right: 0;
   padding-left: 0;
}

.navbar-expand .navbar-nav {
   flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
   position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
   padding-right: 0.5rem;
   padding-left: 0.5rem;
}

.navbar-expand>.container,
.navbar-expand>.container-fluid,
.navbar-expand>.container-sm,
.navbar-expand>.container-md,
.navbar-expand>.container-lg,
.navbar-expand>.container-xl {
   flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
   display: flex !important;
   flex-basis: auto;
}

.navbar-expand .navbar-toggler {
   display: none;
}

.navbar-dark .navbar-brand {
   color: #fff;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
   color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
   color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
   color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
   color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show>.nav-link,
.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
   color: #fff;
}

.navbar-dark .navbar-toggler {
   color: rgba(255, 255, 255, 0.5);
   border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
   color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
   color: #fff;
}

.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
   color: #fff;
}

.card-link:hover {
   text-decoration: none;
}

.card-link+.card-link {
   margin-left: 1.25rem;
}

.card-img {
   flex-shrink: 0;
   width: 100%;
}

.card-img {
   border-top-left-radius: calc(0.25rem - 1px);
   border-top-right-radius: calc(0.25rem - 1px);
}

.card-img {
   border-bottom-right-radius: calc(0.25rem - 1px);
   border-bottom-left-radius: calc(0.25rem - 1px);
}

.bg-primary {
   background-color: #389ebd !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
   background-color: #964a2c !important;
}

.bg-dark {
   background-color: #343a40 !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
   background-color: #1d2124 !important;
}

.rounded-circle {
   border-radius: 50% !important;
}

.d-none {
   display: none !important;
}

.d-block {
   display: block !important;
}

@media (min-width: 992px) {
   .d-lg-none {
      display: none !important;
   }

   .d-lg-block {
      display: block !important;
   }
}

.fixed-top {
   position: fixed;
   top: 0;
   right: 0;
   left: 0;
   z-index: 1030;
}

.mx-auto {
   margin-right: auto !important;
}

.mx-auto {
   margin-left: auto !important;
}

.text-primary {
   color: #389ebd !important;
}

a.text-primary:hover,
a.text-primary:focus {
   color: #824027 !important;
}

.text-warning {
   color: #ffc107 !important;
}

a.text-warning:hover,
a.text-warning:focus {
   color: #ba8b00 !important;
}

.visible {
   visibility: visible !important;
}

.invisible {
   visibility: hidden !important;
}

@media (min-width: 992px) {
   body {
      padding-top: 0;
      padding-left: 17rem;
   }
}

h1,
h2,
h3,
h4,
h5,
h6 {
   text-transform: uppercase;
}

h1 {
   line-height: 1;
}

p.lead {
   font-size: 1.15rem;
   font-weight: 400;
}

.subheading {
   font-family: "Saira Extra Condensed", -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
   text-transform: uppercase;
   font-weight: 500;
   font-size: 1.5rem;
}

#sideNav .navbar-nav .nav-item .nav-link {
   font-weight: 800;
   letter-spacing: 0.05rem;
   text-transform: uppercase;
}

#sideNav .navbar-toggler:focus {
   outline-color: #d48a6e;
}

@media (min-width: 992px) {
   #sideNav {
      text-align: center;
      position: fixed;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      width: 17rem;
      height: 100vh;
   }

   #sideNav .navbar-brand {
      display: flex;
      margin: auto auto 0;
      padding: 0.5rem;
   }

   #sideNav .navbar-brand .img-profile {
      max-width: 10rem;
      max-height: 10rem;
      border: 0.5rem solid rgba(255, 255, 255, 0.2);
   }

   #sideNav .navbar-collapse {
      display: flex;
      align-items: flex-start;
      flex-grow: 0;
      width: 100%;
      margin-bottom: auto;
   }

   #sideNav .navbar-collapse .navbar-nav {
      flex-direction: column;
      width: 100%;
   }

   #sideNav .navbar-collapse .navbar-nav .nav-item {
      display: block;
   }

   #sideNav .navbar-collapse .navbar-nav .nav-item .nav-link {
      display: block;
   }
}

.social-icons .social-icon {
   display: inline-flex;
   align-items: center;
   justify-content: center;
   height: 3.5rem;
   width: 3.5rem;
   background-color: #495057;
   color: #fff;
   border-radius: 100%;
   font-size: 1.5rem;
   margin-right: 2.5rem;
}

.social-icons .social-icon:last-child {
   margin-right: 0;
}

.social-icons .social-icon:hover {
   background-color: #389ebd;
}

.dev-icons {
   font-size: 3rem;
}

section.resume-section {
   display: flex;
   align-items: center;
   padding-left: 1rem;
   padding-right: 1rem;
   padding-top: 5rem;
   padding-bottom: 5rem;
   max-width: 75rem;
}

section.resume-section .resume-section-content {
   width: 100%;
}

@media (min-width: 768px) {
   section.resume-section {
      min-height: 100vh;
   }
}

@media (min-width: 992px) {
   section.resume-section {
      padding-left: 3rem;
      padding-right: 3rem;
      padding-top: 5rem;
      padding-bottom: 5rem;
   }
}

@media (min-width: 300px) {
   .projects {
      height: auto !important;
   }

   .button {
      background-color: rgb(255, 255, 255) !important;
   }
}